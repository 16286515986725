import React, { useEffect, useState } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import debounce from "lodash.debounce";
import { FormLabel, TextInput } from "../FormInputs/TextInput";
import { Submit } from "../FormInputs/Submit";
import { CompanySize } from "../FormInputs/CompanySize";
import { ImageUpload } from "../FormInputs/ImageUpload";
import { Sectors } from "../FormInputs/Sectors";
import { Speciality } from "components/FormInputs/Speciality";
import {
  submitCompanyInformationForm,
  getCompany,
} from "actions/companyActions";
import { getCurrentUser } from "actions/authActions";
import { Select } from "../FormInputs/Select";
import { ExpertiseRepeater } from "../FormInputs/ExpertiseRepeater";
import { OnSubmitValidationError } from "../../utils/OnSubmitValidationError";
import { Trades } from "../FormInputs/Trades";
import { GlobalPresentMulti } from "../GlobalPresenceMulti/GlobalPresentMulti";
import { TabHeader } from "../TabHeader";
import { TabContent } from "../Tabs/styled";
import { IndigenousCheckBoxWrapper } from "./styled";
import { Checkbox3 } from "../FormInputs/Checkbox3";
import { formatABN, isValidAbnOrAcn } from "../../utils/helpers";
import { FlashError } from "../FormInputs/FlashError";
import { toast } from "react-toastify";
import { FormatedInput } from "components/FormInputs/FormatedInput";
import _ from "lodash";

const AutoSave = ({ debounceMs }) => {
  const formik = useFormikContext();
  // eslint-disable-next-line
  const debouncedSubmit = React.useCallback(
    debounce(() => {
      if(formik.isSubmitting) return;
      if(!formik.isSubmitting) formik.submitForm()
    }, debounceMs),
    [formik.submitForm,formik.isSubmitting]
  );

  useEffect(() => {
    if(!formik.isSubmitting){
      debouncedSubmit();
    }else{
      return () => debouncedSubmit.cancel();
    }
    return () => debouncedSubmit.cancel();
    
  }, [debouncedSubmit, formik.values, formik.isSubmitting]);

  return true;
};

export const CompanyInformationFormComponent = ({
  company,
  companyId,
  getCompany,
  getCurrentUser,
  ...props
}) => {
  const [initialValues, setInitialValues] = useState(props.initialValues);

  const [errorStatus, setErrorStatus] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [errorBusinesName,setErrorBusinesName] = useState({})
  const [isErrorBusinesName,setIsErrorBusinessName] = useState(false)
  const accountStatusOptions = [
    { label: "Hidden", value: "hidden" },
    { label: "Visible", value: "published" },
  ];
  const [savedField, setSavedField] = useState(props.initialValues);

  const globalPresenceOptions = props.globalPresenceData.map((g) => {
    return {
      value: g.id,
      label: g.name,
    };
  });

  useEffect(() => {
    if (
      JSON.stringify(savedField.logo) !== JSON.stringify(initialValues.logo)
    ) {
      setInitialValues(savedField);
    }
    if (
      JSON.stringify(savedField.banner_image) !==
      JSON.stringify(initialValues.banner_image)
    ) {
      setInitialValues(savedField);
    }
  }, [savedField, initialValues, setInitialValues]);

  return initialValues.name !== undefined ? (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object({
        name: Yup.string().required().max(80, "Business name should not exceed 80 characters"),
        company_number: Yup.string(),
        sector_ids: Yup.array().min(1, "You must select at least one sector."),
        speciality_ids:
          company.profile_type.id === 3 || company.profile_type.id === 2
            ? Yup.string().required()
            : "",
        description: Yup.string().required(),
        contact_name: Yup.string().required(),
        phone: Yup.string()
          .required("* THIS FIELD IS REQUIRED")
          .matches(
            /^(?=.*)((?:\+?61) ?(?:\((?=.*\)))?([2-47-8])\)?|(?:\((?=.*\)))?([0-1][2-47-8])\)?) ?-?(?=.*)((\d{1} ?-?\d{3}$)|(00 ?-?\d{4} ?-?\d{4}$)|( ?-?\d{4} ?-?\d{4}$)|(\d{2} ?-?\d{3} ?-?\d{3}$))/,
            "Invalid Phone No."
          ),
        disciplines: Yup.array().when("profile_type", {
          is: (profile_type) => profile_type === 4,
          then: Yup.array().of(
            Yup.object().shape({
              expertise: Yup.string().required(),
              disciplines: Yup.array().required().min(1),
            })
          ),
        }),
        abn: Yup.string()
          .required("* THIS FIELD IS REQUIRED")
          .test(
            "test abn/acn",
            "*INVALID ABN/ACN",
            (value) => value && isValidAbnOrAcn(value)
          )
          .nullable(true),
        email: Yup.string().email().required(),
        website: Yup.string(),
        status: Yup.string().required(),
        size_type_id:
          company.profile_type.id !== 1 ? Yup.string().required() : "",
        trade_ids: company.profile_type.id === 3 ? Yup.string().required() : "",
        global_presence:
          company.profile_type.id !== 1 ? Yup.array().required() : "",
        years_trading:
          company.profile_type.id !== 1 ? Yup.number().required() : "",
        // keywords:Yup.array().required(),
      })}
      onSubmit={async (values, { setStatus }) => {
        try {
          await props.submitCompanyInformationForm(
            values.company_id,
            values,
            setSavedField
          );
          showToast && toast.success("Data updated successfully.");
          setIsErrorBusinessName(false)
          setErrorBusinesName({})
          setShowToast(false);
        } catch (e) {
        
          const error = await e.response?.json();
          setErrorStatus(true);
          setStatus(error?.message);
          if(error?.message==="The given data was invalid."){
         
          
            setIsErrorBusinessName(true)
            setErrorBusinesName(error)
            //The name has already been taken.
          }
        }
      }}
    >
      {(props) => {
        const {
          setFieldValue,
          isSubmitting,
          values,
          status,
          errors,
          setStatus,
          setErrors,
        } = props;
        const onSubmitValidationError = () => {
        
          setStatus("required");
          setErrorStatus(true);
          console.log("required fields", errors);
        };
        const onMaxCharacter = () => {
          setStatus("maxCharacter");
          setErrorStatus(true);
        };

        //global presence values
        const globalPresenceInitialValues = initialValues.global_presence.map(
          (g) => {
            return {
              value: g.id,
              label: g.name,
            };
          }
        );
        let globalPresenceIds = values.global_presence?.map(
          (g) => g.id || g.value
        );
        let globalPresenceValueObj = globalPresenceOptions.filter((g) =>
          globalPresenceIds?.includes(g.value)
        );

        return (
          <Form onChange={() => setErrorStatus(false)}>
            {!isSubmitting &&
              !errorStatus &&
              !_.isEqual(savedField, values) && <AutoSave debounceMs={3000} />}
            <TabHeader heading="Company Information" />
            <TabContent>
              <div className="row">
                <div className="col xl-12">
                  <div className="row">
                    <div className="col xl-12">
                      {company.profile_type.id !== 1 && (
                        <div className="row">
                          <Select
                            label="Profile Visibility *"
                            name="status"
                            className="col xl-6"
                            setFieldValue={setFieldValue}
                            options={accountStatusOptions}
                            guideLine="Control when your profile is made visible or hidden to our members."
                            value={accountStatusOptions.filter((option) => {
                              return values.status === option.value;
                            })}
                          />
                        </div>
                      )}
                      <div className="row">
                        <TextInput
                          label="Business Name"
                          required="true"
                          name="name"
                          type="text"
                          className="col xl-6 name"
                          maxLength={80}
                        />
                        <IndigenousCheckBoxWrapper className="col xl-6 indigenous">
                          <FormLabel>Indigenous Business </FormLabel>

                          <Checkbox3
                            name="indigenous_buisness"
                            label="Are you an Indigenous Business"
                          />
                        </IndigenousCheckBoxWrapper>
                        <TextInput
                          label="Website"
                          name="website"
                          type="text"
                          className="col xl-6"
                        />
                        <TextInput
                          label="ABN/ACN *"
                          placeholder="ABN/ACN"
                          name="abn"
                          type="text"
                          className="col xl-6 abn"
                          formatValue={formatABN}
                          setFieldValue={setFieldValue}
                        />

                        {company.profile_type.id !== 1 && (
                          <CompanySize
                            name="size_type_id"
                            required={company.profile_type.id !== 1}
                            value={values.size_type_id}
                            profileType={values.profile_type}
                            editForm
                            className="col xl-6"
                            guideLine="This is the size of the business relevant to your State or Territory (include all other offices located within the same State or Territory)."
                          />
                        )}

                        {company.profile_type.id !== 1 ? (
                          <TextInput
                            label="Number of Years Trading"
                            required="true"
                            name="years_trading"
                            type="number"
                            className="col xl-6"
                            guideLine="Will be updated every year"
                          />
                        ) : (
                          <></>
                        )}
                        <div className="col xl-12">
                          <div className="row">
                            <ImageUpload
                              label="Company Logo"
                              name="logo"
                              image={props.initialValues.logo}
                              setFieldValue={setFieldValue}
                              className="col lg-6"
                              width={"100%"}
                              column
                              uploadImage
                            />

                            <ImageUpload
                              label="Banner Image"
                              name="banner_image"
                              image={props.initialValues.banner_image}
                              setFieldValue={setFieldValue}
                              width={"100%"}
                              className="col lg-6"
                              column
                              uploadImage
                              guideLine="Recommended banner image resolution is 600x 400 pixels for optimal display quality."
                            />
                          </div>
                        </div>
                        {company.profile_type.id !== 1 ? (
                          <GlobalPresentMulti
                            options={globalPresenceOptions}
                            name="global_presence"
                            setFieldValue={setFieldValue}
                            label="Global Presence"
                            required={true}
                            defaultValue={globalPresenceInitialValues}
                            values={globalPresenceValueObj}
                            isClearable={true}
                            className="col xl-12 global_presence"
                            sortingValue
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <Sectors
                  label="Relevant Sectors / Experience"
                  required="true"
                  name="sector_ids"
                  setFieldValue={setFieldValue}
                  defaultValue={initialValues.sector_ids}
                  values={values}
                  sortingValue
                  className="col xl-12"
                />

                {/* consultants */}
                {company.profile_type.id === 4 && (
                  <ExpertiseRepeater
                    label="Services provided by company"
                    name="disciplines"
                    values={values.disciplines}
                    profileType={values.profile_type}
                    className="col xl-12"
                    required
                  />
                )}

                <FormatedInput
                  label="Company Description"
                  required="true"
                  name="description"
                  characterLimit={1500}
                  setErrors={setErrors}
                  onMaxCharacter={onMaxCharacter}
                  className="col xl-12"
                  guideLine="Tell us about your business and let clients know your mission, vision and/or approach and what you can offer to the industry."
                />

                {(company.profile_type.id === 2 ||
                  company.profile_type.id === 3) && (
                  <Speciality
                    label="Specialty"
                    required="true"
                    name="speciality_ids"
                    setFieldValue={setFieldValue}
                    values={values}
                    className="col xl-12"
                  />
                )}

                {/* subcontractors */}
                {company.profile_type.id === 3 && (
                  <Trades name="trade_ids" required />
                )}

                <TextInput
                  label="Contact Person Name"
                  required="true"
                  name="contact_name"
                  type="text"
                  className="col xl-6"
                  guideLine="This is the main point of contact for your account and profile."
                />

                <TextInput
                  label="Phone Number"
                  required="true"
                  name="phone"
                  type="text"
                  className="col xl-6 phone"
                />

                <TextInput
                  label="Email Address"
                  required="true"
                  name="email"
                  type="text"
                  className="col xl-6 email"
                />
                <div className="col xl-8">
                  {status && !_.isEmpty(errors) && (
                    <FlashError
                      heading={errorMessages[status]?.heading || status}
                      text={errorMessages[status]?.description || status}
                      margin="0px 0px 20px 0px"
                    />
                  )}
                  {isErrorBusinesName && <>
                    <FlashError
                      heading={errorBusinesName.message}
                      text="The name has already been taken."
                      margin="0px 0px 20px 0px"
                    />
                  </>}
                </div>
              </div>

              <TextInput
                name="company_id"
                value={initialValues.company_id}
                type="hidden"
              />
              <Submit
                type="submit"
                onClick={() => {
                  setShowToast(true);
                }}
                isSubmitting={isSubmitting}
                spinner
                text="Save Profile"
                submittingText="Saving..."
                disabled={!_.isEmpty(errors)}
              />
              <OnSubmitValidationError callback={onSubmitValidationError} />
            </TabContent>
          </Form>
        );
      }}
    </Formik>
  ) : (
    <Skeleton count={5} duration={0.5} />
  );
};
const errorMessages = {
  required: {
    heading: "* Required Field",
    description: "There are incomplete required fields, Please complete them.",
  },
  connection: {
    heading: "Connection not established",
    description: "Unable to process your request",
  },
  maxCharacter: {
    heading: "Input has reached max character",
    description: "Input has reached max character",
  },
  
};

const mapStateToProps = (state) => {
  const company = state.search.activeCompany;
  const companyId = state.search.activeCompany.id;
  const globalPresenceData = state.globalPresence.data;

  return {
    company: company,
    companyId: companyId,
    globalPresenceData,
    initialValues: {
      logo: company.logo_path || "",
      banner_image: company.banner_path || "",
      name: company.name || "",
      company_number: company.company_number || "",
      sector_ids: Array.isArray(company.sector)
        ? company.sector.map((sector) => sector.id)
        : [],
      speciality_ids: Array.isArray(company.speciality)
        ? company.speciality.map((speciality) => speciality.id)
        : [],
      size_type_id: company.size_type_id || "",
      description: company.description || "",
      keywords: company.keywords || [],
      services: company.services || [],
      contact_name: company.contact_name || "",
      phone: company.phone || "",
      email: company.email || "",
      website: company.website || "",
      company_id: company.id || "",
      status: company.status || "",
      disciplines: company.disciplinesFormatted.length
        ? company.disciplinesFormatted
        : [{ expertise: "", disciplines: [] }],
      profile_type: company.profile_type.id,
      trade_ids: Array.isArray(company.trade)
        ? company.trade.map((trade) => trade.id)
        : [],
      years_trading: parseInt(company.years_trading) || "",
      global_presence: company.global_presence.map((g) => {
        return {
          value: g.id,
          label: g.name,
        };
      }),
      abn: company.abn,
      indigenous_buisness: company.indigenous_buisness,
    },
  };
};

export const CompanyInformationForm = connect(mapStateToProps, {
  submitCompanyInformationForm,
  getCurrentUser,
  getCompany,
})(CompanyInformationFormComponent);
