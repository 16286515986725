import React, { useState } from "react";
import { ReactComponent as Pencil } from "./../../icons/pencil.svg";
import { findUserRole } from "utils/helpers";
import { ButtonWithIcon } from "components/Button";
import { navigate } from "@reach/router";
import { ContentRow, ContentCollumn } from "components/CustomTable";
import { connect } from "react-redux";
import { ReactComponent as Email } from "../../icons/email.svg";
import { LabelHover } from "components/LabelHover";

export const AccountContentRowComponent = ({
  item,
  i,
  datas,
  current_user,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <ContentRow>
      <ContentCollumn className="md-3 data-cell first">
        {item.full_name}
      </ContentCollumn>
      <ContentCollumn className="md-2 data-cell">
        {findUserRole(item.position)?.label || <>&mdash;</>}
      </ContentCollumn>
      <ContentCollumn className="md-2 data-cell">
        {item.phone || <>&mdash;</>}
      </ContentCollumn>
      <ContentCollumn className="md-3 data-cell no-border">
        <a
          className="icon-email"
          target="_blank"
          href={`mailto:${item.email}`}
          rel="noopener noreferrer"
        >
          <Email
            width={"24px"}
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
            style={{ cursor: "pointer" }}
          />
        </a>
        <LabelHover text={"mail to: " + item.email} hovered={hover} />
      </ContentCollumn>
      <ContentCollumn className="md-2 data-cell last">
        {item.id !== current_user.id && (
          <ButtonWithIcon
            icon={<Pencil />}
            text={"EDIT"}
            btntype={"secondary"}
            onClick={() => {
              navigate("account-users/edit-user-account", {
                state: { user: item, company_users: datas },
              });
            }}
          />
        )}
      </ContentCollumn>
    </ContentRow>
  );
};

const mapStateToProps = (state) => {
  const current_user = state.auth.user;

  return {
    current_user: current_user,
  };
};

export const AccountContentRow = connect(mapStateToProps)(
  AccountContentRowComponent
);
