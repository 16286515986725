import React, { useState } from "react";

import { ContextMenuStyled, AccountBadge } from "./styled";
import { IconBox } from "../IconBox";
import { ContextMenuLink } from "./ContextMenuLink";
import { ReactComponent as UsersIcon } from "../../icons/person.svg";
import { ReactComponent as SettingsIcon } from "../../icons/settings.svg";
import { ReactComponent as BuildingIcon } from "../../icons/building.svg";
import { ReactComponent as MapPinIcon } from "../../icons/map-pin.svg";
import { ReactComponent as CheckmarkIcon } from "../../icons/checkmark.svg";
import { ReactComponent as PeopleIcon } from "../../icons/people.svg";
import { ReactComponent as DocumentIcon } from "../../icons/document.svg";
import { ReactComponent as SharedProfileIcon } from "../../icons/shared-icon.svg";
import { ReactComponent as LogIcon } from "../../icons/documents.svg";
import { ReactComponent as OpenEye } from "../../icons/eye.svg";
import { ReactComponent as AccountUsersIcon } from "../../icons/shared-profile.svg";
import { ReactComponent as ShareIcon } from "../../icons/share-icon.svg";

import {
  isAdmin,
  isBidder,
  isProcurer,
  isProcurerNBidder,
  isSuperAdmin,
} from "../../utils/helpers";
import { connect } from "react-redux";
import { ShareProfileModal } from "../ShareProfile/ShareProfileModal";

export const ContextMenuComponent = ({ user, company, ...props }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  return (
    <>
      <ContextMenuStyled>
        <AccountBadge name={props.name} logo={props.logo}></AccountBadge>
          <ContextMenuLink to="company-profile/edit-user-profile">
            <IconBox icon={<UsersIcon />} color="dark" />
            My Profile
          </ContextMenuLink>


        {(isAdmin(user) || isSuperAdmin()) &&
           (
            <>
              <ContextMenuLink to="company-profile/account-users">
                <IconBox icon={<AccountUsersIcon />} color="dark" />
                Account Users
              </ContextMenuLink>
              <ContextMenuLink to="company-profile/account-management">
                <IconBox icon={<SettingsIcon />} color="dark" />
                Account Management
              </ContextMenuLink>
              <ContextMenuLink to="company-profile/company-information">
                <IconBox icon={<BuildingIcon />} color="dark" />
                Company Information
              </ContextMenuLink>
              {(isProcurerNBidder(company) ||
                isProcurer(company) ||
                isBidder(company) ||
                isSuperAdmin()) && (
                <ContextMenuLink to="company-profile/company-locations">
                  <IconBox icon={<MapPinIcon />} color="dark" />
                  Office Locations
                </ContextMenuLink>
              )}
            </>
          )}

        {((isAdmin(user) && isProcurerNBidder(company)) ||
          isBidder(company) ||
          isSuperAdmin()) && (
            <>
              <ContextMenuLink to="company-profile/projects">
                <IconBox icon={<CheckmarkIcon />} color="dark" />
                Projects & Experience
              </ContextMenuLink>
              <ContextMenuLink to="company-profile/resources">
                <IconBox icon={<PeopleIcon />} color="dark" />
                Key Personnel & Expertise
              </ContextMenuLink>
            </>
          )}

        {((isAdmin(user) && !isProcurer(company)) || isSuperAdmin()) &&
          (
            <ContextMenuLink to="company-profile/documents">
              <IconBox icon={<DocumentIcon />} color="dark" />
              Corporate Documents
            </ContextMenuLink>
          )}
        {company.profile_type?.id !== 1 &&
          (
            <ContextMenuLink to={`/company/${company.id}`} newTab={true}>
              <IconBox icon={<OpenEye />} color="dark" />
              Preview your Profile
            </ContextMenuLink>
          )}
           <ContextMenuLink to="company-profile/shared-companies">
            <IconBox icon={<SharedProfileIcon />} color="dark" />
            Shared Profiles
          </ContextMenuLink>

        {(isAdmin(user) || isSuperAdmin()) &&(
            <ContextMenuLink to="company-profile/logs">
              <IconBox icon={<LogIcon />} color="dark" />
              Logs
            </ContextMenuLink>
          )}
        {(isProcurerNBidder(company) || isBidder(company) || isSuperAdmin()) && (
            <div className="profile-share" onClick={() => setIsOpenModal(true)}>
              <IconBox icon={<ShareIcon />} color="dark" />
              <span>Share Profile</span>
            </div>
          )}
      </ContextMenuStyled>
      <ShareProfileModal isOpen={isOpenModal} setIsOpen={setIsOpenModal} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    company: state.search.activeCompany,
  };
};

export const ContextMenu = connect(mapStateToProps, {})(ContextMenuComponent);
