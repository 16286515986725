import React from "react";
import { AccountContentRow } from "./AccountRow";
import { AccountHeaderRow } from "./HeaderRow";
import { CustomTableContainer } from "components/CustomTable/CustomTable";

export const AccountUsersTable = ({ datas }) => {
  return (
    <CustomTableContainer
      datas={datas}
      perPage={7}
      Header={AccountHeaderRow}
      Content={AccountContentRow}
    ></CustomTableContainer>
  );
};
