import React from "react";
import { CompanyCardStyled, RemoveButton } from "./styled";
import { CompanyHeaderMedia } from "../CompanyHeaderMedia";
import { navigate } from "@reach/router";
import { useSelector } from "react-redux";
import { isSuperAdmin } from "utils/helpers";

export const CompanyCard = ({
  location,
  handleRemoveButtonClick,
  linkNewTab,
  accountType,
  company_profile_status,
  ...props
}) => {
  const COLORREADYTOVIEW = "#00C59C";
  const COLORFOUNDINGMEMBER = "#4864F9";
  const COLORPROFILEUNDERCONSTRUCTION = "#F38744";
  const link = props.admin
    ? `/account/${props.id}/company-profile/company-information`
    : `/company/${props.id}`;
  const { account_type_id } = useSelector(
    (state) => state?.auth?.current_company
  );

  const bgColorBasedStatus = (status) => {
    status = status?.toLowerCase();
    if (status === "new member") {
      return COLORFOUNDINGMEMBER;
    } else if (status === "profile ready to view") {
      return COLORREADYTOVIEW;
    } else if (status === "profile under construction") {
      return COLORPROFILEUNDERCONSTRUCTION;
    } else {
      return "none";
    }
  };

  const navigateFunc = (link, linkNewTab) => {
    if (isSuperAdmin()) {
      if (linkNewTab) {
        window.open(link, "_blank");
        return null;
      } else {
        navigate(link);
      }
    } else {
      let loggedInAccountType = parseInt(account_type_id);
      let dataAccountType = parseInt(accountType);
      if (
        loggedInAccountType === 1 &&
        loggedInAccountType === dataAccountType
      ) {
        //Procurer can't open Profile with Account Type Procurer
        return null;
      } else if (loggedInAccountType === 3 && dataAccountType === 1) {
        //Bidder and Procurer can't open Profile with Account Type Procurer
        return null;
      } else {
        if (loggedInAccountType === 1 || loggedInAccountType === 3) {
          if (linkNewTab) {
            window.open(link, "_blank");
            return;
          } else {
            navigate(link);
          }
        }
      }
    }
  };

  const generateClassName = (divClassName) => {
    if (divClassName?.trim() === "") {
      return "";
    } else {
      let modifiedString = divClassName?.replace(/ /g, "-");
      return modifiedString?.toLowerCase();
    }
  };

  const statuses = company_profile_status?.name?.includes(" and ") ? company_profile_status?.name?.split(" and ")?.reverse() : [company_profile_status?.name] ;

  return (
    <CompanyCardStyled
      {...props}
      onClick={() => navigateFunc(link, linkNewTab)}
      id={`company company-${props.id}`}
      style={{ cursor: "pointer" }}
    >
      <div
        className="company-card__inner"
        style={{ borderRadius: "15px", overflow: "hidden" }}
      >
        <div className="company-card__header" style={{position: 'relative'}}>
          <div className="status-container" style={{position: 'absolute',display: 'flex', gap: '8px', margin: '16px 12px', zIndex: 999999}}>
          
         { statuses?.length > 0 && statuses.map((status, index) => (
          <div
            style={{ position: "relative", width: 'max-content' }}
            className={generateClassName(status)}
          >
            {status && (
              <div
                style={{
                  height: "36px",
                  background: bgColorBasedStatus(status),
                  textAlign: "center",
                  top: 0,
                  width: "100%",
                  zIndex: "10",
                  color: "#fff",
                  fontFamily: "roboto",
                  fontSize: "14px",
                  fontWeight: "400",
                  padding: "8px 8px",
                  borderRadius: "6px",
                }}
              >
                {status?.toUpperCase()}
              </div>
              )}
            </div>
         ))}
          

          </div>
          
          {handleRemoveButtonClick && (
            <RemoveButton
              type="remove"
              onClick={(e) => handleRemoveButtonClick(e, props.id)}
            >
              remove
            </RemoveButton>
          )}
          <CompanyHeaderMedia
            bg={
              props.banner
                ? props.banner
                : `${process.env.PUBLIC_URL}/img/placeholder-lg.jpg`
            }
            logo={
              props.logo
                ? props.logo
                : `${process.env.PUBLIC_URL}/img/placeholder-lg.jpg`
            }
          />
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "1.375rem",
            display: "block",
            fontWeight: 500,
            color: "rgb(38, 56, 89)",
            lineHeight: 1.25,
            margin: "0px 0px 0.75rem",
            padding: "0px 1rem",
          }}
        >
          {props.name}
        </div>
        {props.profileType && (
          <p className="company-card__subtitle capitalize">
            {props.profileType.name}
          </p>
        )}
        {location && location.value && (
          <p className="company-card__subtitle">{location.value}</p>
        )}
      </div>
    </CompanyCardStyled>
  );
};

CompanyCard.defaultProps = {
  location: "",
  linkNewTab: false,
};
